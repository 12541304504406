import { Button, Menu } from 'antd';
import Sider from 'antd/es/layout/Sider';
import React from 'react'
import { Link, useLocation } from 'react-router-dom';
import NavBarButtons from './NavBarButtons';
import { BulbOutlined, UserOutlined } from '@ant-design/icons';
import {useNavigate} from 'react-router-dom'
import TgIcon from './tg_icon';

const SiderElem = () => {
    const navigate = useNavigate()
    const location = useLocation()

    const changeTab = (event: any) => {
        switch (event.key) {
            case (1):
                navigate('/account')
                break
            case (2):
                navigate('/strategies')
                break
            default:
                return
        }
    }

    return (
        <Sider
            breakpoint="lg"
            collapsedWidth="0"
        >
            <div className="logo">
                <div className='img-container' style={{maxWidth: 80, margin: "20px auto 5px auto", pointerEvents: "none", userSelect: "none"}}>
                    <img alt="" src='./images/android-chrome-192x192.png'/>
                </div>
                <h2 style={{color: "#fff", textAlign: "center", fontWeight: 600, fontSize: 18, marginBottom: 10, pointerEvents: "none", userSelect: "none"}}>AlgoLab-X<br />Margin Trade</h2>
            </div>
            <Menu
                onClick={(event) => changeTab(event)}
                theme="dark"
                mode="inline"
                defaultSelectedKeys={location.pathname == "/strategies" ? ["2"] : ["1"]}
                items={[{
                    key: 1,
                    icon: React.createElement(UserOutlined),
                    label: <Link to='/account'>Аккаунт</Link>
                },
                {
                    key: 2,
                    icon: React.createElement(BulbOutlined),
                    label: <Link to='/strategies'>Стратегии</Link>
                }]}>
            </Menu>
                
            <Button type="link" target='_blank' href='https://t.me/margin_tv_trader_bot' className='link' 
                style={{display: "flex", color: "#faea9a", flexDirection: "row", fontWeight: 600, alignItems: "center", marginLeft: 8, marginTop: 10}} 
                icon={<TgIcon />}>Наш Telegram Bot</Button>
        </Sider>
    )
} 


export default SiderElem