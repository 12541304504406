export const coins = [
    "BTC/USDT",
    "ETH/USDT",
    "BNB/USDT",
    "NEO/USDT",
    "LTC/USDT",
    "QTUM/USDT",
    "ADA/USDT",
    "XRP/USDT",
    "EOS/USDT",
    "TUSD/USDT",
    "IOTA/USDT",
    "XLM/USDT",
    "ONT/USDT",
    "TRX/USDT",
    "ETC/USDT",
    "ICX/USDT",
    "NULS/USDT",
    "VET/USDT",
    "BCH/USDT",
    "USDC/USDT",
    "LINK/USDT",
    "WAVES/USDT",
    "HOT/USDT",
    "ZIL/USDT",
    "ZRX/USDT",
    "FET/USDT",
    "BAT/USDT",
    "XMR/USDT",
    "ZEC/USDT",
    "IOST/USDT",
    "CELR/USDT",
    "DASH/USDT",
    "THETA/USDT",
    "ENJ/USDT",
    "MATIC/USDT",
    "ATOM/USDT",
    "TFUEL/USDT",
    "ONE/USDT",
    "FTM/USDT",
    "ALGO/USDT",
    "DOGE/USDT",
    "DUSK/USDT",
    "ANKR/USDT",
    "COS/USDT",
    "MTL/USDT",
    "TOMO/USDT",
    "DENT/USDT",
    "KEY/USDT",
    "DOCK/USDT",
    "CHZ/USDT",
    "BAND/USDT",
    "BNB/BUSD",
    "BTC/BUSD",
    "BUSD/USDT",
    "XTZ/USDT",
    "RVN/USDT",
    "HBAR/USDT",
    "NKN/USDT",
    "XRP/BUSD",
    "ETH/BUSD",
    "BCH/BUSD",
    "LTC/BUSD",
    "LINK/BUSD",
    "ETC/BUSD",
    "STX/USDT",
    "KAVA/USDT",
    "ARPA/USDT",
    "TRX/BUSD",
    "EOS/BUSD",
    "IOTX/USDT",
    "RLC/USDT",
    "XLM/BUSD",
    "ADA/BUSD",
    "CTXC/USDT",
    "TROY/USDT",
    "VET/BUSD",
    "OGN/USDT",
    "ICX/BUSD",
    "BTS/USDT",
    "LTO/USDT",
    "ATOM/BUSD",
    "DASH/BUSD",
    "NEO/BUSD",
    "XTZ/BUSD",
    "BAT/BUSD",
    "ENJ/BUSD",
    "ONT/BUSD",
    "MBL/USDT",
    "COTI/USDT",
    "ALGO/BUSD",
    "XMR/BUSD",
    "STPT/USDT",
    "SOL/USDT",
    "SOL/BUSD",
    "CTSI/USDT",
    "HIVE/USDT",
    "CHR/USDT",
    "HBAR/BUSD",
    "MATIC/BUSD",
    "ZIL/BUSD",
    "MDT/USDT",
    "STMX/USDT",
    "KNC/USDT",
    "LRC/BUSD",
    "LRC/USDT",
    "DGB/BUSD",
    "COMP/BUSD",
    "COMP/USDT",
    "ZEN/USDT",
    "SNX/USDT",
    "DGB/USDT",
    "SXP/USDT",
    "MKR/USDT",
    "MKR/BUSD",
    "RUNE/BUSD",
    "MANA/BUSD",
    "DOGE/BUSD",
    "STORJ/USDT",
    "AVA/BUSD",
    "MANA/USDT",
    "YFI/USDT",
    "BAL/USDT",
    "BLZ/USDT",
    "ANT/USDT",
    "CRV/BUSD",
    "CRV/USDT",
    "SAND/USDT",
    "SAND/BUSD",
    "OCEAN/BUSD",
    "OCEAN/USDT",
    "NMR/BUSD",
    "NMR/USDT",
    "DOT/BUSD",
    "DOT/USDT",
    "LUNA/BUSD",
    "LUNA/USDT",
    "IDEX/BUSD",
    "RSR/BUSD",
    "RSR/USDT",
    "PAXG/BUSD",
    "PAXG/USDT",
    "TRB/BUSD",
    "TRB/USDT",
    "SUSHI/USDT",
    "KSM/BUSD",
    "KSM/USDT",
    "EGLD/BUSD",
    "EGLD/USDT",
    "DIA/USDT",
    "RUNE/USDT",
    "UMA/USDT",
    "BEL/BUSD",
    "BEL/USDT",
    "WING/BUSD",
    "WING/USDT",
    "UNI/BUSD",
    "UNI/USDT",
    "AVAX/BUSD",
    "AVAX/USDT",
    "CAKE/BUSD",
    "ORN/USDT",
    "UTK/USDT",
    "XVS/USDT",
    "ALPHA/USDT",
    "AAVE/USDT",
    "NEAR/BUSD",
    "NEAR/USDT",
    "FIL/BUSD",
    "FIL/USDT",
    "INJ/BUSD",
    "INJ/USDT",
    "ONE/BUSD",
    "AUDIO/BUSD",
    "AUDIO/USDT",
    "CTK/USDT",
    "AKRO/USDT",
    "KP3R/BUSD",
    "AXS/BUSD",
    "AXS/USDT",
    "HARD/BUSD",
    "HARD/USDT",
    "UNFI/USDT",
    "FRONT/BUSD",
    "ROSE/BUSD",
    "ROSE/USDT",
    "AVA/USDT",
    "SKL/USDT",
    "GRT/USDT",
    "1INCH/USDT",
    "REEF/USDT",
    "CELO/USDT",
    "RIF/USDT",
    "TRU/USDT",
    "TUSD/BUSD",
    "CKB/USDT",
    "TWT/USDT",
    "LIT/BUSD",
    "LIT/USDT",
    "SFP/BUSD",
    "SFP/USDT",
    "DODO/BUSD",
    "DODO/USDT",
    "CAKE/USDT",
    "BAKE/BUSD",
    "GRT/BUSD",
    "REEF/BUSD",
    "AUCTION/BUSD",
    "PHA/BUSD",
    "TVK/BUSD",
    "BADGER/BUSD",
    "BADGER/USDT",
    "OM/BUSD",
    "OM/USDT",
    "POND/BUSD",
    "POND/USDT",
    "DEGO/BUSD",
    "DEGO/USDT",
    "ALICE/BUSD",
    "ALICE/USDT",
    "LINA/BUSD",
    "LINA/USDT",
    "PERP/USDT",
    "SUPER/BUSD",
    "SUPER/USDT",
    "CFX/USDT",
    "PUNDIX/USDT",
    "TLM/BUSD",
    "TLM/USDT",
    "HOT/BUSD",
    "BAKE/USDT",
    "BURGER/BUSD",
    "BURGER/USDT",
    "SLP/BUSD",
    "SLP/USDT",
    "SHIB/USDT",
    "SHIB/BUSD",
    "ICP/BUSD",
    "ICP/USDT",
    "AR/USDT",
    "MDX/BUSD",
    "MDX/USDT",
    "MASK/BUSD",
    "MASK/USDT",
    "LPT/BUSD",
    "LPT/USDT",
    "FTM/BUSD",
    "KAVA/BUSD",
    "GTC/BUSD",
    "GTC/USDT",
    "COTI/BUSD",
    "CHR/BUSD",
    "ERN/USDT",
    "KLAY/BUSD",
    "KLAY/USDT",
    "IOTX/BUSD",
    "PHA/USDT",
    "BOND/BUSD",
    "BOND/USDT",
    "C98/USDT",
    "C98/BUSD",
    "CLV/BUSD",
    "CLV/USDT",
    "QNT/BUSD",
    "QNT/USDT",
    "FLOW/BUSD",
    "FLOW/USDT",
    "TVK/USDT",
    "MINA/BUSD",
    "MINA/USDT",
    "QUICK/USDT",
    "REQ/USDT",
    "WAXP/USDT",
    "WAXP/BUSD",
    "MTL/BUSD",
    "OGN/BUSD",
    "DYDX/USDT",
    "DYDX/BUSD",
    "IDEX/USDT",
    "GALA/USDT",
    "GALA/BUSD",
    "YGG/USDT",
    "YGG/BUSD",
    "STX/BUSD",
    "DF/USDT",
    "ARPA/BUSD",
    "FIDA/USDT",
    "FIDA/BUSD",
    "RAD/BUSD",
    "RAD/USDT",
    "HIVE/BUSD",
    "BETA/USDT",
    "LAZIO/USDT",
    "AUCTION/USDT",
    "CELO/BUSD",
    "DAR/USDT",
    "DAR/BUSD",
    "BNX/USDT",
    "LAZIO/BUSD",
    "ENS/BUSD",
    "ENS/USDT",
    "ANKR/BUSD",
    "KP3R/USDT",
    "QI/USDT",
    "PORTO/USDT",
    "JASMY/USDT",
    "JASMY/BUSD",
    "PYR/BUSD",
    "PYR/USDT",
    "RNDR/USDT",
    "RNDR/BUSD",
    "ALCX/USDT",
    "SANTOS/USDT",
    "MC/USDT",
    "BICO/BUSD",
    "BICO/USDT",
    "FLUX/BUSD",
    "FLUX/USDT",
    "FXS/USDT",
    "REQ/BUSD",
    "CTXC/BUSD",
    "HIGH/USDT",
    "PEOPLE/BUSD",
    "PEOPLE/USDT",
    "NULS/BUSD",
    "SPELL/USDT",
    "SPELL/BUSD",
    "JOE/USDT",
    "ACH/BUSD",
    "ACH/USDT",
    "IMX/BUSD",
    "IMX/USDT",
    "GLMR/BUSD",
    "GLMR/USDT",
    "API3/USDT",
    "WOO/USDT",
    "ASTR/BUSD",
    "ASTR/USDT",
    "GMT/BUSD",
    "GMT/USDT",
    "KDA/BUSD",
    "KDA/USDT",
    "APE/USDT",
    "APE/BUSD",
    "BSW/USDT",
    "BSW/BUSD",
    "SANTOS/BUSD",
    "PORTO/BUSD",
    "REI/USDT",
    "GAL/USDT",
    "GAL/BUSD",
    "LDO/BUSD",
    "LDO/USDT",
    "EPX/USDT",
    "EPX/BUSD",
    "REI/BUSD",
    "LUNC/BUSD",
    "USTC/BUSD",
    "OP/BUSD",
    "OP/USDT",
    "KEY/BUSD",
    "LEVER/USDT",
    "LEVER/BUSD",
    "GLM/BUSD",
    "STG/BUSD",
    "STG/USDT",
    "AMB/BUSD",
    "LUNC/USDT",
    "PHB/BUSD",
    "VIB/BUSD",
    "GMX/BUSD",
    "GMX/USDT",
    "AGIX/BUSD",
    "POLYX/BUSD",
    "POLYX/USDT",
    "APT/USDT",
    "APT/BUSD",
    "OSMO/USDT",
    "OSMO/BUSD",
    "HFT/BUSD",
    "HFT/USDT",
    "PHB/USDT",
    "HOOK/USDT",
    "HOOK/BUSD",
    "MAGIC/BUSD",
    "MAGIC/USDT",
    "AGIX/USDT",
    "GNS/USDT",
    "SYN/USDT",
    "VIB/USDT",
    "SSV/USDT",
    "LQTY/USDT",
    "AMB/USDT",
    "USTC/USDT",
    "ID/USDT",
    "ARB/USDT",
    "OAX/USDT",
    "RDNT/USDT",
    "EDU/USDT",
    "SUI/USDT",
    "PEPE/USDT",
    "FLOKI/USDT",
    "COMBO/USDT"
]